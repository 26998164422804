import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const SecondaryLink = ({
  text,
  to,
  bordered,
  className = '',
  isExternal,
  onClick,
}) => {
  const borderStyles = bordered ? 'shadow-sm border border-gray-light' : '';
  return isExternal ? (
    <a
      className={`${className} text-gray-500 hover:text-gray-700 py-2.5 px-3.5 rounded-3p ${borderStyles} text-center whitespace-nowrap`}
      href={to}
      onClick={() => onClick()}
    >
      {text}
    </a>
  ) : (
    <Link
      className={`${className} text-gray-500 hover:text-gray-700 py-2.5 px-3.5 rounded-3p ${borderStyles} text-center whitespace-nowrap`}
      to={to}
      onClick={() => onClick()}
    >
      {text}
    </Link>
  );
};
export default SecondaryLink;

SecondaryLink.propTypes = {
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  bordered: PropTypes.bool,
  className: PropTypes.string,
  isExternal: PropTypes.bool,
  onClick: PropTypes.func,
};
