import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

const FooterItem = ({ title, links }) => {
  const intl = useIntl();
  return (
    <div className="">
      <p className="font-semibold mb-4 text-sm text-gray-400">
        {intl.formatMessage({ id: title })}
      </p>
      <ul className="list-none">
        {links.map((link) => (
          <li className="list-none mb-4" key={link.title}>
            {link.isExternal ? (
              <a
                href={link.url}
                target="_blank"
                rel="noreferrer"
                className="hover:text-gray-400"
              >
                {intl.formatMessage({ id: link.title })}
              </a>
            ) : (
              <Link to={link.url} className="hover:text-gray-400">
                {intl.formatMessage({ id: link.title })}
              </Link>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

FooterItem.propTypes = {
  title: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default FooterItem;
