import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useIntl } from "gatsby-plugin-react-intl";

import US from "../../images/US.svg";
import ES from "../../images/ES.svg";
import PT from "../../images/PT.svg";

const LanguageSelector = () => {
  const [open, setOpen] = useState();
  const [locale, setLocale] = useState({});
  const intl = useIntl();

  const getFlag = (loc) => {
    switch (loc) {
      case "es":
        return ES;
      case "en":
        return US;
      default:
        return PT;
    }
  };

  useEffect(() => {
    setLocale({
      code: intl.locale.toUpperCase(),
      flag: getFlag(intl.locale),
    });
  }, [intl.locale]);

  const toggleOpen = () => setOpen(open ? "" : "open");

  return (
    <div className="relative flex space-x-4 items-center w-16 flex-shrink-0">
      <button
        className={`${open} selector text-sm flex space-x-1 border-none bg-white mr-8 items-center`}
        onClick={toggleOpen}
      >
        <img width="16" src={locale.flag} alt="English" />
        <span>{locale.code}</span>
      </button>
      <div className={`${open ? "max-h-32" : "max-h-0"} lg-list`}>
        <div className="px-4 py-2.5  space-y-4">
          <a href="/en" className="flex space-x-1">
            <img width="16" src={US} alt="English" />
            <span>English</span>
          </a>
          <a href="/es" className="flex space-x-1">
            <img width="16" src={ES} alt="Spanish" />
            <span>Español</span>
          </a>
          <a href="/pt" className="flex space-x-1">
            <img width="16" src={PT} alt="Portuguese" />
            <span>Portuguese</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default LanguageSelector;
