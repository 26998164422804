import React, { useEffect, useState } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import brand from '../../images/brand.svg';
import FooterItem from './FooterItem';

import tw from '../../images/social/tw.svg';
import ig from '../../images/social/ig.svg';
import ln from '../../images/social/ln.svg';

const Footer = () => {
  const intl = useIntl();

  const items = [
    {
      title: 'footer.menu.title',
      links: [
        {
          title: 'footer.menu.home',
          url: `/${intl.formatMessage({ id: 'footer.intl' })}/#main`,
        },
        {
          title: 'footer.menu.wedo',
          url: `/${intl.formatMessage({ id: 'footer.intl' })}/#we-do`,
        },
        {
          title: 'footer.menu.usecases',
          url: `/${intl.formatMessage({ id: 'footer.intl' })}/#use-cases`,
        },
        {
          title: 'footer.menu.bepart',
          url: `/${intl.formatMessage({ id: 'footer.intl' })}/#be-part`,
        },
      ],
    },
    {
      title: 'footer.agrotoken.title',
      links: [
        {
          title: 'footer.agrotoken.whitepaper',
          url: `https://register.agrotoken.io/bundles/app/whitepaper_${intl.formatMessage(
            { id: 'footer.intl' }
          )}.pdf`,
          isExternal: true,
        },
        {
          title: 'footer.agrotoken.help',
          url: intl.formatMessage({ id: 'helpCenterUrl' }),
          isExternal: true,
        },
        {
          title: 'footer.agrotoken.access',
          url: intl.formatMessage({ id: 'loginUrl' }),
          isExternal: true,
        },
        {
          title: 'footer.agrotoken.register',
          url: intl.formatMessage({ id: 'signupUrl' }),
        },
      ],
    },
    {
      title: 'footer.institucional.title',
      links: [
        {
          title: 'footer.institucional.terms',
          url: intl.formatMessage({ id: 'termsAndCondsUrl' }),
          isExternal: true,
        },
        {
          title: 'footer.institucional.privacy',
          url: intl.formatMessage({ id: 'privacyUrl' }),
          isExternal: true,
        },
        {
          title: 'footer.institucional.cookies',
          url: intl.formatMessage({ id: 'cookiesUrl' }),
          isExternal: true,
        },
        {
          title: 'footer.institucional.contact.text',
          url: intl.formatMessage({ id: 'footer.institucional.contact.url' }),
        },
        {
          title: 'footer.institucional.press',
          url: 'mailto:press@agrotoken.io',
        },
        {
          title: 'footer.institucional.bases',
          url: process.env.BASES_AND_CONDITIONS_PROMOTIONS,
          isExternal: true,
        },
      ],
    },
    {
      title: 'footer.social.title',
      links: [
        {
          title: 'footer.social.twitter',
          url: 'https://twitter.com/agrotoken',
          isExternal: true,
        },
        {
          title: 'footer.social.instagram',
          url: 'https://www.instagram.com/agrotoken/',
          isExternal: true,
        },
        {
          title: 'footer.social.linkedIn',
          url: 'https://www.linkedin.com/company/agrotoken',
          isExternal: true,
        },
      ],
    },
  ];

  return (
    <footer className="bg-gray-900 text-gray-lighter lg:px-28 px-4 pt-16">
      <div className="max-w-7xl mx-auto flex flex-col lg:flex-row">
        <div className="lg:w-1/4 lg:mr-16">
          <img src={brand} alt="agrotoken" width="182" />
          <p className="mt-9">{intl.formatMessage({ id: 'footer.heading' })}</p>
        </div>
        <div className="flex flex-col lg:flex-row flex-1 justify-between">
          {items.map((item) => (
            <FooterItem
              key={item.title}
              title={item.title}
              links={item.links}
            />
          ))}
        </div>
      </div>
      <hr className="border-gray-700" />
      <div className="max-w-7xl mx-auto mt-8 pb-12 flex justify-between">
        <p className="mt-2 text-gray-400">
          {intl.formatMessage({ id: 'footer.copyright' })}
        </p>
        <div className="flex space-x-6 items-center">
          <a
            href="https://twitter.com/agrotoken"
            target="_blank"
            rel="noreferrer"
          >
            <img src={tw} alt="tw" />
          </a>
          <a
            href="https://www.instagram.com/agrotoken/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={ig} alt="ig" />
          </a>
          <a
            href="https://www.linkedin.com/company/agrotoken"
            target="_blank"
            rel="noreferrer"
          >
            <img src={ln} alt="li" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
